import "vue-js-modal/dist/styles.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "./vendor/bootstrap.stickyheader";

import "./assets/scss/main.scss";

import "./assets/js/scrollin";

Vue.config.productionTip = true;

if ("development" == process.env.NODE_ENV) {
  axios.defaults.baseURL = "/api/";
}

Vue.prototype.$http = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
