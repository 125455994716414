<template>
  <div class="home page">
    <VideoHeader />
    <TextContent
      title="Sportzahnmedizin – die erste Adresse für Hamburg und Umgebung"
      subtitle="Für Spitzensportler und Athleten, die ihre Leistungsfähigkeit nachhaltig steigern wollen"
    >
      <p>
        Sichern Sie sich echte Wettbewerbsvorteile, minimieren Sie das Risiko
        von Verletzungen und holen Sie das Maximum aus Ihrer Leistung heraus –
        mittels moderner Sportzahnmedizin. In unseren Praxen im Westen, Osten
        und in der Mitte von Hamburg steht Ihr sportlicher Erfolg stets im
        Mittelpunkt, denn sowohl Probleme mit dem Kiefer oder den Zähnen als
        auch Erkrankungen des Mundraums können einen enormen Einfluss auf Ihre
        Kraftentwicklung, Körperstabilität und Leistungsfähigkeit im Allgemeinen
        haben. Innovative Sportzahnmedizin, basierend auf dem außergewöhnlichen
        Know-how und der langjährigen Erfahrung promovierter Sportzahnärzte
        („Dental Mouthguard“), hilft dabei, Ihr volles Potential als Vereins-
        oder Spitzensportler auszuschöpfen. Angefangen bei der Prophylaxe über
        die Entwicklung individueller Sport-Gebissschutze zur Vermeidung von
        Verletzungen bis hin zur Beseitigung von anderen Störfaktoren, die Ihre
        sportliche Performance oder die Wettbewerbsvorbereitung negativ
        beeinflussen – unser Sportzahnarzt-Team betreut Sie, Ihre Mannschaft
        oder Ihren Sportverein an den Standorten Hamburg-Nienstedten,
        Hamburg-St. Pauli und Hamburg-Uhlenhorst persönlich und nachhaltig.
        Damit Sie es allen zeigen können. Informieren Sie sich im Folgenden gern
        über unser vielfältiges Leistungsspektrum für Spitzensportler aus
        Hamburg und der Region.
      </p>
      <h3>Prophylaxe</h3>
      <p>
        Leistungsmindernden Erkrankungen der Zähne, des Kiefers oder Mundraums
        mittels professioneller Sportzahnmedizin effektiv vorbeugen
      </p>
      <h3>Leistungsfähigkeit</h3>
      <p>
        Spezielle Zahnschienen und ein individuelles Maßnahmen-Paket für die
        nachhaltige Steigerung Ihrer sportlichen Leistungen
      </p>
      <h3>Verletzungsprävention</h3>
      <p>
        Individueller Sport-Gebissschutz bzw. Sportmundschutz zur Minimierung
        des Verletzungsrisikos und Vermeidung von Langzeitschäden nach einem
        Sportunfall
      </p>
    </TextContent>
    <TeamSection />
    <TextContent
      title="Pre-Season-Check – weil nach der Saison vor der Saison ist"
    >
      <p>
        Mit einem zahnmedizinischen Pre-Season-Check stellen Sie die Weichen für
        kommende Wettbewerbe oder die nächste Erfolgssaison – ganz so, wie es
        sich auch für Olympia-Athleten und internationale Profi-Sportler seit
        Jahren bewährt hat. Als Ihre professionellen Sportzahnärzte in Hamburg
        bieten wir Ihnen einen umfassenden Check – bestehend aus zahnärztlichen
        Untersuchungen, Leistungs- und Funktionsanalysen – an. Schnelle
        Ergebnisse helfen dabei, bestehende oder aufkeimende Erkrankungen und
        damit Risiken für Ihre Leistung proaktiv zu erkennen und zu behandeln.
        Kurzum: Nach unserem Pre-Season-Check kann die nächste Herausforderung
        für Sie oder Ihren Verein kommen.
      </p>
      <h3>Die Vorteile im Überblick:</h3>
      <ul>
        <li>Minimierung von Verletzungsrisiken</li>
        <li>Behebung leistungsmindernder Störfaktoren</li>
        <li>Perfektionierung der sportlichen Leistungsfähigkeit</li>
      </ul>
    </TextContent>
    <PartnerSection />
    <TextContent
      title="Elbmedicum: Sportliche Leistungssteigerung messbar machen"
    >
      <p>
        Das Elbmedicum in Hamburg ist das Sport-Kompetenzzentrum für Athleten
        und Spitzensportler aller Kategorien und Arten. Das vielfältige Angebot
        erstreckt sich über Sport- und Physiotherapie, maßgeschneiderte
        Behandlungskonzepte und Rehabilitation bis hin zu individuell
        abgestimmten Maßnahmen für die nachhaltige Leistungsoptimierung.
      </p>
      <h3>Vorbereitende Sportphysiotherapie:</h3>
      <p>
        Eine individuell auf Sie zugeschnittene sportphysiotherapeutische
        Behandlung – ggf. ergänzt um eine Behandlung craniomandibulärer
        Dysfunktionen – dient als Grundlage für die Herstellung und den Einsatz
        Ihrer neuen Performanceschiene.
      </p>
      <h3>Performancetests und individuelle Leistungsanalysen:</h3>
      <p>
        Mittels einer individuellen Leistungsdiagnostik und Funktionsanalyse,
        basierend auf der von Ihnen ausgeübten Sportart, wird ein umfassender
        Ist-Status Ihrer sportlichen Leistungsfähigkeit ermittelt. Hochmoderne
        Messtechniken und -Werkzeuge ermöglichen einen detaillierten
        1:1-Vergleich nach dem Einsatz einer Performance-Zahnschiene.
      </p>
    </TextContent>
    <KontaktSection />
  </div>
</template>

<script>
// @ is an alias to /src
import VideoHeader from "@/components/VideoHeader.vue";
import TextContent from "@/components/TextContent.vue";
import TeamSection from "@/components/TeamSection.vue";
import PartnerSection from "@/components/PartnerSection.vue";
import KontaktSection from "@/components/KontaktSection.vue";

export default {
  components: {
    VideoHeader,
    TextContent,
    TeamSection,
    PartnerSection,
    KontaktSection
  }
};
</script>
