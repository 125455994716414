<template>
  <section>
    <div class="container container-kontakt">
      <header>
        <h1>Kontakt</h1>
      </header>
      <div class="row">
        <div class="col-12 col-lg-8">
          <form
            class="row"
            @submit.prevent="submit"
            v-bind:class="{ 'is-sending': isSending, send: send }"
            v-if="!send"
          >
            <div class="col-12 col-lg-6">
              <div class="form-group floatinglabel">
                <input
                  type="text"
                  class="form-control"
                  id="inputName"
                  placeholder=" "
                  required
                  v-model="contact.name"
                />
                <label for="inputName">Name, Vorname</label>
              </div>
              <div class="form-group floatinglabel">
                <input
                  type="text"
                  class="form-control"
                  id="inputTelefon"
                  placeholder=" "
                  required
                  v-model="contact.telefon"
                />
                <label for="inputTelefon">Telefonnummer</label>
              </div>
              <div class="form-group floatinglabel">
                <input
                  type="email"
                  class="form-control"
                  id="inputEMail"
                  placeholder=" "
                  required
                  v-model="contact.email"
                />
                <label for="inputEMail">E-Mail</label>
              </div>
              <div class="form-group floatinglabel">
                <textarea
                  id="inputNachricht"
                  class="form-control"
                  rows="3"
                  placeholder=" "
                  v-model="contact.nachricht"
                />
                <label for="inputNachricht">Ihre Nachricht</label>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <p>
                In welcher unserer Praxen hätten Sie gerne bevorzugt einen
                Termin?
              </p>
              <div class="form-group custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  name="bevorzugtePraxis"
                  id="bevorzugtePraxis1"
                  value="Hamburg West | Dr. Vera Leisentritt | Nienstedtener Str. 30 | 22609 Hamburg | 040 82294220"
                  required
                  v-model="contact.praxis"
                />
                <label class="custom-control-label" for="bevorzugtePraxis1">
                  Hamburg{{ "\xa0" }}West{{ "\xa0" }}| Dr.{{ "\xa0" }}Vera{{
                    "\xa0"
                  }}Leisentritt{{ "\xa0" }}| Nienstedtener{{ "\xa0" }}Str{{
                    "\xa0"
                  }}
                  30{{ "\xa0" }}| 22609{{ "\xa0" }}Hamburg{{ "\xa0" }}| 040{{
                    "\xa0"
                  }}82294220
                </label>
              </div>
              <div class="form-group custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  name="bevorzugtePraxis"
                  id="bevorzugtePraxis3"
                  value="Hamburg Ost | Dr. Thomas Löbkens | Grillparzerstraße 2 | 22085 Hamburg | 040 224012"
                  required
                  v-model="contact.praxis"
                />
                <label class="custom-control-label" for="bevorzugtePraxis3">
                  Hamburg{{ "\xa0" }}Ost{{ "\xa0" }}| Dr.{{ "\xa0" }}Thomas{{
                    "\xa0"
                  }}Löbkens{{ "\xa0" }}| Grillparzerstraße{{ "\xa0" }}2{{
                    "\xa0"
                  }}| 22085{{ "\xa0" }}Hamburg{{ "\xa0" }}| 040{{
                    "\xa0"
                  }}224012
                </label>
              </div>
              <div
                class="form-group custom-control custom-checkbox privacy-consent"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  name="check_einwilligung"
                  id="check_einwilligung"
                  value="einwilligung"
                  required
                  v-model="contact.check_einwilligung"
                />
                <label class="custom-control-label" for="check_einwilligung">
                  Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur
                  Beantwortung meiner Anfrage erhoben und verarbeitet werden.
                  Die Daten werden nach abgeschlossener Bearbeitung Ihrer
                  Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung
                  jederzeit für die Zukunft per E-Mail an
                  info@sportzahnaerzte-im-norden.de widerrufen.
                </label>
              </div>

              <button type="submit" class="btn btn-outline-primary btn-submit">
                Absenden
              </button>
            </div>
          </form>
          <div v-if="send">
            <p>
              Vielen Dank für Ihre Nachricht. Wir werden uns bei Ihnen melden.
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-4 addressdata">
          <h3>Sportzahnärzte im Norden</h3>
          <address>
            Grillparzerstraße 2<br />
            22085 Hamburg
          </address>
          <p>
            <a href="mailto:info@sportzahnaerzte-im-norden.de" target="_blank"
              >info@sportzahnaerzte-im-norden.de</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "KontaktSection",
  props: {},
  data: function() {
    return {
      contact: {
        name: "",
        telefon: "",
        email: "",
        nachricht: "",
        praxis: null,
        check_einwilligung: false
      },
      isSending: false,
      send: false
    };
  },
  methods: {
    /**
     * Clear the form
     */

    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
    },

    /**
     * Handler for submit
     */

    submit(e) {
      this.isSending = true;
      // e.srcElement.classList.add("is-sending");
      console.log(e);
      setTimeout(() => {
        // Build for data
        let form = new FormData();
        for (let field in this.contact) {
          form.append(field, this.contact[field]);
        }

        // Send form to server
        this.$http
          .post("/php/app.php", form)
          .then(response => {
            console.log(response);
            this.clearForm();
            this.isSending = false;
            this.send = true;
          })
          .catch(e => {
            console.log(e);
          });
      }, 1000);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"></style> -->
<style lang="scss">
@import "src/assets/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

form {
  transition: opacity 0.3s;
  &.is-sending {
    opacity: 0.3;
  }
  p {
    margin: 1em 0;
    &:first-child {
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
  .container-kontakt & {
    @include media-breakpoint-up(lg) {
      .col-lg-6 {
        // display: flex;
        // justify-content: space-between;
        // flex-flow: column;
      }
      .btn-submit {
        margin-top: auto;
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }
  .privacy-consent label {
    font-size: 0.7rem;
  }
}

#inputNachricht {
  @include media-breakpoint-up(lg) {
    min-height: 250px;
  }
}

.addressdata {
  &:before {
    content: "";
    display: block;
    border-top: 2px solid $dark;
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }
  @include media-breakpoint-up(lg) {
    &:before {
      display: none;
    }
    border-left: 2px solid $dark;
    &[class*="col"] {
      padding-left: $grid-gutter-width;
    }
  }
  h3 {
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
</style>
