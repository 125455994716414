<template>
  <footer id="page-footer" class="page-footer">
    <div class="container">
      <ul class="foorternav">
        <li class="copy">&copy; {{ year }} Sportzahnärzte im Norden</li>
        <li>
          <router-link :to="{ name: 'impressum' }" rel="nofollow"
            >Impressum</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'datenschutz' }" rel="nofollow"
            >Datenschutz</router-link
          >
        </li>
        <li class="break"></li>
        <li class="social">
          <ul class="sociallist">
            <!-- <li>
              <a
                href="https://www.facebook.com"
                target="_blank"
                title="Facebook"
                ><span class="icon icon-fb"></span
              ></a>
            </li> -->
            <li>
              <a
                href="https://www.instagram.com/sportzahnaerzte.im.norden/?hl=de"
                target="_blank"
                title="Instagram"
                ><span class="icon icon-ig"></span
              ></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  props: {},
  data: function() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
