<template>
  <section>
    <div class="container">
      <header>
        <h1>Unser Team</h1>
      </header>
      <!-- <modal width="600" height="auto" :scrollable="true" name="teaminfo">
      foobar
      <div class="modal-details-wrapper" v-html="teamInfoDetails">
        text
      </div>
    </modal> -->
      <ul class="team">
        <li v-for="(item, index) in members" :key="index">
          <img
            v-bind:src="item.image"
            v-bind:alt="item.name"
            class="team-member"
          />

          <h2 v-on:click.stop.prevent="open_info(index)">
            {{ item.name }}
            <span class="icon icon-info" v-if="item.info"></span>
          </h2>
          <h3 class="team-position">{{ item.position }}</h3>
          <ul class="awards" v-if="item.awards">
            <li v-for="(award, index) in item.awards" :key="index">
              <img
                v-bind:src="award.image"
                v-bind:alt="award.name"
                class="award"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
// import VModal from "vue-js-modal";
import VModal from "vue-js-modal/dist/index.nocss.js";
import TeamInfo from "./TeamInfo.vue";

// https://euvl.github.io/vue-js-modal/
Vue.use(VModal);

export default {
  name: "TeamSection",
  props: {},
  // components: { modal: VModal },
  methods: {
    open_info: function (index) {
      this.$modal.show(
        TeamInfo,
        { title: this.members[index].name, content: this.members[index].info },
        {
          scrollable: true,
          height: "auto",
        },
        {
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose,
        }
      );
    },
    beforeOpen: function () {
      // When the modal is shown, we want a fixed body
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      // When the modal is hidden, we want to remain at the top of the scroll position
      // document.body.style.position = "";
      // document.body.style.top = "";
    },
    beforeClose: function () {
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    },
  },
  data: function () {
    return {
      showModal: false,
      members: [
        {
          name: "Dr. Vera Leisentritt",
          position:
            "zertifizierte Spezialistin für ästhetische Zahnmedizin und Funktion",
          image: require("../../src/assets/img/dr_vera_leisentritt_sportzahnarzt-hamburg.jpg"),
          awards: [
            // {
            //   image: require("@/assets/img/focus_siegel_aesthetische_zahnheilkunde.jpg")
            // },
            {
              image: require("@/assets/img/Focus_Mediziner-Siegel_Sportmedizin_2023_150x355.png"),
            },
          ],
          info: `
<table>
<tr><th>1971</th><td>Geboren</td></tr>
<tr><th>2000–Heute</th><td>Zahlreiche Fortbildungen im In- und Ausland, unter anderem an der Harvard School of Dental Medicine</td></tr>
<tr><th>2008–Heute</th><td>Aktives Mitglied der DGÄZ (Deutschen Gesellschaft für Ästhetische Zahnheilkunde, www.dgaez.de)</td></tr>
<tr><th>2014</th><td>Zertifizierung als Spezialistin für „Ästhetische Zahnmedizin und Funktion“ in der DGÄZ und als Spezialistin „Rekonstruktive Zahnmedizin, Ästhetik und Funktion“ in der EDA (European Dental Association)</td></tr>
<tr><th>2014–Heute</th><td>Referententätigkeit</td></tr>
<tr><th>2015–Heute</th><td>Vizepräsidentin der DGÄZ</td></tr>
<tr><th>2019</th><td>Masterstudium Ästhetik und Funktion, M.Sc.</td></tr>
</table>`,
        },
        {
          name: "Dr. Thomas Löbkens",
          position: "Zahnheilkunde & Implantologie ",
          image: require("../../src/assets/img/dr_thomas_loebkens_sportzahnarzt-hamburg.jpg"),
          info: `
<table>
<tr><th>1962</th><td>Geboren</td></tr>
<tr><th>1984–1987</th><td>Studium der Betriebswirtschafts&shy;lehre an der Hamburger Wirtschaftsakademie mit dem Abschluss Betriebswirt</td></tr>
<tr><th>1988–1994</th><td>Studium der Zahnmedizin an der Universität Hamburg</td></tr>
<tr><th>1994</th><td>Staatsexamen und Approbation</td></tr>
<tr><th>1994–1996</th><td>Assistenzarzt in der Praxis Dr. Löbkens</td></tr>
<tr><th>1997</th><td>Praxisübernahme </td></tr>
<tr><th>1998</th><td>Promotion</td></tr>
</table>`,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- style scoped lang="scss"></style -->
<style lang="scss">
@import "src/assets/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/assets/scss/bootstrap/mixins/grid-framework";
@import "src/assets/scss/bootstrap/mixins/grid";

.team {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  @include media-breakpoint-only(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-row();
    li {
      @include make-col(4);
    }
  }
  > li {
    margin: 2rem auto;
  }
  h2 {
    cursor: pointer;
    .icon {
      margin-left: 0.5em;
      position: relative;
      top: 4px;
    }
  }
}
.team-member {
  width: 200px;
  height: auto;
  border: 8px solid $white;
  border-radius: 50%;
}
.team-position {
  font-weight: 400;
  text-align: center;
}
.awards {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 80%;

  > li {
    margin-left: 20px;
    margin-right: 20px;
    &:first-child:last-child {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .award {
    max-width: 100%;
    height: auto;
  }
}
</style>
