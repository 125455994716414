<template>
  <div class="header-section">
    <div class="container">
      <video
        width="160"
        height="90"
        autoplay
        loop
        muted
        playsinline
        src="@/assets/video/SZIN_Clip_Header_01_SW_60.768.mp4"
        poster="@/assets/video/SZIN_Clip_Header_01_SW_60.768.jpg"
      >
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoHeader",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"></style> -->
<style lang="scss">
@import "src/assets/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
.header-section {
  video {
    width: 100%;
    height: auto;
  }
  .container {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}
</style>
