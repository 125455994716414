<template>
  <div id="app" class="body-bg-top">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <header class="navbar fixed-top navbar-light bg-light">
      <div class="container">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
          <span class="sr-only">Sportzahnärzte im Norden</span>
        </router-link>
      </div>
    </header>
    <router-view />
    <FooterSection />
  </div>
</template>

<style lang="scss"></style>

<script>
import FooterSection from "@/components/FooterSection.vue";

export default {
  components: {
    FooterSection
  }
};
</script>
