import "../scss/scrollin.scss";

if (document.readyState === "loading") {
  // Loading hasn't finished yet
  document.addEventListener("DOMContentLoaded", initScrollin);
} else {
  // `DOMContentLoaded` has already fired
  // Falls document.readyState !== 'loading' aber body Element noch nicht zur Verfügung steht.
  let timer = window.setInterval(function() {
    if (document.getElementsByTagName("body").length) {
      window.clearInterval(timer);
      initScrollin();
    }
  }, 50);
}

function initScrollin() {
  if (window.IntersectionObserver) {
    document
      .querySelectorAll("section, .team > li, .partnerlist > li")
      .forEach((frame) => {
        observer.observe(frame);
      });
  }
}

// Scroll-in Animation
let observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (
        entry.boundingClientRect.top > 0 ||
        entry.target.className.indexOf("outview") > -1
      ) {
        if (entry.isIntersecting) {
          entry.target.classList.add("inview");
          entry.target.classList.remove("outview");
        } else {
          if (entry.intersectionRatio <= 0) {
            entry.target.classList.add("outview");
            entry.target.classList.remove("inview");
          }
        }
      }
    });
  },
  { rootMargin: "0px 0px -100px 0px" }
);
