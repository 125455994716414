<template>
  <section>
    <div class="container">
      <header>
        <h1>Partner</h1>
      </header>
      <ul class="partnerlist">
        <li v-for="(item, index) in partners" :key="index">
          <a v-if="item.link" v-bind:href="item.link" target="_blank">
            <img
              v-bind:src="item.image.src"
              v-bind:alt="item.image.alt"
              v-bind:title="item.image.title || item.image.alt"
            />
          </a>
          <img
            v-else
            v-bind:src="item.image.src"
            v-bind:alt="item.image.alt"
            v-bind:title="item.image.title || item.image.alt"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnerSection",
  props: {},
  data: function() {
    return {
      partners: [
        {
          image: {
            src: require("@/assets/img/logo_elbmedicum.svg"),
            alt: "Elbmedicum"
          },
          link: "https://www.elbmedicum.de"
        },
        {
          image: {
            src: require("@/assets/img/logo_dgszm.jpg"),
            alt: "DGZM"
          }
        },
        {
          image: {
            src: require("@/assets/img/logo_deutsche_sporthilfe.svg"),
            alt: "Deute Sporthilfe"
          }
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"></style> -->
<style lang="scss">
@import "src/assets/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
.partnerlist {
  list-style: none;
  margin: 0;
  padding: 0;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  li {
    padding: $grid-gutter-width;
    flex: 1;
    &:first-child {
      padding-top: 0;
    }
    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 1rem;
    }
  }
  img {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}
</style>
