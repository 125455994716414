<template>
  <div>
    <button @click="$emit('close')" class="icon icon-close"></button>
    <header>
      <h1>{{ title }}</h1>
    </header>
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
  props: {
    title: String,
    content: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"></style> -->
<style lang="scss">
@import "src/assets/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "src/assets/scss/bootstrap/mixins/grid-framework";
@import "src/assets/scss/bootstrap/mixins/grid";

.vm--container {
  z-index: 1999;
}
.vm--overlay {
  background: rgba(white, 0.5);
}

.vm--modal {
  border-radius: 0;
  box-shadow: 0 0 10px rgba(black, 0.15);
  width: 100% !important;
  left: 0 !important;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;

  @include media-breakpoint-up(md) {
    padding: 40px;
  }
  @include media-breakpoint-only(lg) {
    @include make-col(10);
  }
  @include media-breakpoint-only(xl) {
    @include make-col(8);
  }

  h1 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0.7em;
  }

  .icon-close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  th {
    white-space: nowrap;
    vertical-align: top;
  }
  th + td {
    padding-left: 2em;
  }
  th,
  td {
    padding-bottom: 1rem;
  }
}
</style>
