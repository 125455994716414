<template>
  <section>
    <div class="container">
      <header>
        <div class="text-content">
          <h1>{{ title }}</h1>
          <h2 v-if="subtitle">{{ subtitle }}</h2>
        </div>
      </header>
      <div class="text-content">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TextContent",
  props: {
    title: String,
    subtitle: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"></style> -->
