var stickyheader = document.getElementsByClassName("navbar fixed-top");
function animateHeader() {
  if (!stickyheader.length) {
    return;
  }
  150 < window.scrollY
    ? stickyheader[0].classList.add("navbar-trans")
    : stickyheader[0].classList.remove("navbar-trans");
}
["scroll", "resize", "DOMContentLoaded"].forEach(function(e) {
  window.addEventListener(e, animateHeader);
});
